(function ($) {
  Drupal.behaviors.supercatProductLayoutV2 = {
    attached: false,
    attach: function (context) {
      var self = this;
      if (self.attached) {
        return;
      }
      self.attached = true;
      var $template = $('.js-supercat-container', context);
      var $header = $('.js-supercat-header', $template)
      var $productGrid = $('.js-supercat-grid', $template);
      var windowWidth = $(window).width();
      var isMediumUp = windowWidth >= 1024;

      if (isMediumUp) {
        $template.each(function (value) {
          var $header = $(this).find('.js-supercat-header', $template);
          var $grid = $(this).find('.js-supercat-grid', $template);
          var gridHeight = 0;

          if ($header.length > 0) {
            var headerHeight = $header.height();
            if (headerHeight > 0) {
              gridHeight = headerHeight * (-1);
            }
            $grid.css('margin-top', gridHeight);
          }
        });
      }

      if ($productGrid.find('.js-product-grid--carousel').length !== 0) {
        $header.removeClass('sticky-header');
      }
    }
  };
})(jQuery);
